import BasePlugin from '../BasePlugin'

export default class SetOutgoingDocumentInfringementCommand extends BasePlugin {
  async execute () {
    let me = this
    let records = []
    let outgoingDocumentId = this.context.getParentContext().getModel().id
    let gridInDashboard = this.context.getDashboardComponents()['component_3a05b0fb-53f7-4ecd-8498-c806d493f50a'][0]
    Object.values(gridInDashboard.$refs.table.selected).forEach(function (item) {
      records.push(item.id)
    })
    if (records) {
      let result = await this.context.$http.post(`${this.context.$config.api}/registryservice/plugins/execute/SetOutgoingDocumentInfringementCommand`,
        {
          'outgoingDocumentId': outgoingDocumentId,
          'infringementIds': records
        }, {
          headers: {
            'Content-Type': 'application/json'
          }
        })
      if (result) {
        this.context.$msgbox.close()
        Object.values(this.context.getParentContext().getDashboardComponents()).forEach(function (item) {
          if (item[0].id === 281) {
            item[0].loadData()
          }
        })
        me.context.$msgbox({
          type: 'info',
          message: 'Нарушения включены в предписание'
        })
      } else {
        me.context.$msgbox({
          message: 'Выберите нарушения для включения в предписание'
        })
      }
    }
  }
}
